<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { SbTextContainerStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbTextContainerStoryblok }>()
const renderedTitle = computed(() => renderRichText(blok.title))
const renderedText = computed(() => renderRichText(blok.text))

const collapsible = ref(blok.collapsible)
const isOpened = ref(true)
const padding = computed(() => !blok.noPadding && blok.background ? 'p-5' : '')
const fullWidth = computed(() => blok.fullWidth ? 'w-full' : '')
const fullHeight = computed(() => blok.fullHeight ? 'h-full' : '')
</script>

<template>
  <div
    v-editable="blok"
    :class="tm('text-brand rounded-2xl', blok.background, padding, fullWidth, fullHeight)"
    :style="{
      textAlign: blok.textAlign as any,
    }"
  >
    <CfgTypography
      v-if="blok.title"
      class="mb-3"
      :html="renderedTitle"
      :size="CfgTypographySizes.h2"
      tag-name="h2"
    />

    <CfgTypography
      v-if="renderedText"
      class="prose"
      :class="{
        'line-clamp-4': collapsible && isOpened,
        '[&_p]:mt-1': blok.padding === 'no-padding',
      }"
      :html="renderedText"
      :size="CfgTypographySizes.md"
      tag-name="div"
    />

    <div v-if="collapsible" class="mt-3 flex justify-end">
      <CfgButton
        class="cursor-pointer text-brand underline hover:no-underline"
        flat
        text
        variant="primary"
        @click="isOpened = !isOpened"
      >
        {{ isOpened ? $t('textContainer.showMore') : $t('textContainer.showLess') }}
      </CfgButton>
    </div>
  </div>
</template>
